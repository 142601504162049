<template>
  <div class="leftChildMenu" :style="{ height: szheight? szheight : '100%'}">
    <el-input ref="treeInput" placeholder="输入关键字搜索" v-model="filterText" clearable></el-input>
    <el-scrollbar class="elscrModules">
      <el-tree :data="data" class="flowSchemesTree" node-key="id" :filter-node-method="filterNode"
        :props="props" default-expand-all @node-click="nodeClick" ref="tree">
        <template #default="{ node, data }">
              <!-- <el-icon v-if="data.children && data.children.length > 0">
                <FolderOpened />
              </el-icon>
              <el-icon v-else>
                <Document /> 
              </el-icon> -->
              <span :title="node.label">{{ node.label }}</span>
        </template>
      </el-tree>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'treeFilter',
  emits: ['nodeClick'],
  props: {
    szheight:{type: String, default: null},
    data:{type: Array, default: []},
    props:{type: Object, default: {children: 'children', label: 'name'}},
  },
  data() {
    return {
      filterText: "",
    };
  },
  methods: {
    // tree绑定搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    nodeClick(data,node,treenode,event){
      this.$emit('nodeClick', data,node,treenode,event)
    }
  },
  watch:{
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>

<style>
.leftChildMenu{width: 200px; height:100%;}
.leftChildMenu .elscrModules{
  height: calc(100% - 70px);
  width: 200px;
  min-width: 200px;
  margin-top: 20px;
  color: #686868;
}

/* 覆盖展开图标的样式 */
.flowSchemesTree .el-tree-node__expand-icon {
  content: url('/public/static/images/xxjt.png');
  transform: rotate(-90deg);
}
/* 初始不旋转 */
.flowSchemesTree .el-tree-node__expand-icon.expanded {
  transform: rotate(0deg);
}

/* 点击后内容的颜色切换 */
.flowSchemesTree .el-tree-node:focus>.el-tree-node__content {color: #0AAD94}


/*第一个节点不设置padding-left*/
/* .flowSchemesTree> .el-tree-node{font-size:14px;}
.flowSchemesTree> .el-tree-node:first-child{padding-left:0}
.flowSchemesTree .el-tree-node{padding-left:18px;position:relative}
.flowSchemesTree .el-tree-node .el-tree-node__content{padding-left:0 !important}
.flowSchemesTree .el-tree-node__children{position:relative} */

/*文件夹或者文件前面的横线*/
/* .flowSchemesTree .el-tree-node .custom-tree-node{position:relative;padding-left:10px}
.flowSchemesTree .el-tree-node .custom-tree-node::before,.flowSchemesTree .el-tree-node.is-expanded .custom-tree-node::before{border-bottom:1px solid #ececec;position:absolute;left:0;top:50%;width:7px;height:1px;line-height:1px;content:""} */

/*文件夹或者文件前面的竖线*/
/* .flowSchemesTree > .el-tree-node:first-child::before{border-left:0;}
.flowSchemesTree .el-tree-node::before{position:absolute;left:40px;top:0;width:1px;height: 100%;border-left:1px solid #ececec;content:""}
.flowSchemesTree .el-tree-node > .el-tree-node__children > .el-tree-node:last-child::before,
.flowSchemesTree > .el-tree-node:nth-last-child(2)::before{height: 14px;line-height: 14px;} */


/*修改图标*/
/* .flowSchemesTree .el-tree-node.is-expanded > .el-tree-node__content > .custom-tree-node > .module_tree > .icon-24gl-folderPlus::before,
.flowSchemesTree .el-tree-node.is-expanded > .el-tree-node__content > .custom-tree-node > .children_node > .module_tree > .icon-24gl-folderPlus::before {
  content:"\eabe";
}
.flowSchemesTree .el-tree-node.is-expanded .is-leaf + .custom-tree-node .icon-24gl-folderPlus:before {content: "\eabe";}
.flowSchemesTree .el-tree-node > .el-tree-node__content > .custom-tree-node > .module_tree > .icon-24gl-folderPlus::before,
.flowSchemesTree .el-tree-node > .el-tree-node__content > .custom-tree-node > .children_node > .module_tree > .icon-24gl-folderPlus::before {
  content: "\eabf";
} */

/* 展开图标的颜色 */
/* .flowSchemesTree .el-tree-node__expand-icon {color: #c0c4cc}
.flowSchemesTree .el-tree-node__content .el-tree-node__expand-icon.is-leaf {color: transparent} */
/* 下面样式暂时未使用 */
/* .flowSchemesTreeNew > .el-tree-node:first-child {padding-left: 18px;}
.flowSchemesTreeNew > .el-tree-node:first-child::before {border-left: 1px solid #ececec;} */
</style>


