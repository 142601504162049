<template>
    <video 
        id="jswebrtc"
        ref="player"
        muted
        autoplay
        controls
        style="width: 100%;height: 100%;object-fit: fill;"
    ></video>
</template>

<script>
export default {
    name: 'WebRTCPlayer',
    props: {
        videoSrc: {type: String, default: ''},
    },
    data () {
        return {
            player: null,
        }
    },
    methods: {
        startPlaying(url) {
            if (this.player) {
                this.stopPlaying();
            }
            const videoElement = document.getElementById('jswebrtc');
            this.player = new JSWebrtc.Player(url, {
                video: videoElement,
                autoplay: true,
                onplay: (obj) => console.log('Stream started playing')
            });
        },
        stopPlaying() {
            this.player.destroy();
            this.player = null;
        }
    },
    watch: {
        videoSrc: {
            handler(newVal){
                if (newVal && newVal !== '') {
                    this.$nextTick(() => {
                        this.startPlaying(newVal);
                    })
                } else if (this.player) {
                    this.stopPlaying();
                }
            },
            immediate: true
        }
    },
    beforeDestroy() {
        if (this.player) {
            this.stopPlaying();
        }
    },
};
</script>

<style>
.xideoCSS{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
</style>