<template>
    <!-- flv 视频插件播放 autoplay muted  -->
    <div class="videoGroup">
        <video :id="flvId" muted playsinline webkit-playsinline class="video-js vjs-default-skin flvVideo" :autoplay="false"></video>
    </div>
</template>

<script>
import flvjs from 'flv.js'
export default {
    name: 'flvjsPlayer',
    // components:{
    //     VideoTitle
    // },
    props:{
        flvInfo:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        url:{type: String, default: ''},
        flvId:{type: String, default: ''},
    },
    data(){
        return {
            flvPlayer: null,
            changeLampPost: false,
        }
    },
    created(){},
    mounted(){
        // this.init();
        this.$nextTick(() => {
            this.init();
        })
    },
    methods:{
        init(){
            let _this = this;
            if (flvjs.isSupported()) {
                var videoEE = document.getElementById(_this.flvId);
                var flvPlayer = flvjs.createPlayer(
                    {
                        type: 'flv',
                        isLive: true,
                        url: _this.url
                    },
                    {
                        autoCleanupSourceBuffer: true,
                        autoCleanupMaxBackwardDuration: 1,
                        autoCleanupMinBackwardDuration: 1,
                    }
                );
                flvPlayer.attachMediaElement(videoEE);
                flvPlayer.load();
                // 假设我们只播放2秒钟
                var secondsToPlay = 2;
                setTimeout(function() {
                    flvPlayer.pause(); // 暂停播放
                    flvPlayer.unload(); // 停止加载流，这将关闭与服务器的连接
                    // flvPlayer.detachMediaElement(); // 断开播放器与video元素的关联
                    // flvPlayer.destroy(); // 销毁播放器实例
                }, secondsToPlay * 1000);


                _this.flvPlayer = flvPlayer
                // debugger
                // _this.flvPlayer.on(flvjs.Events.STATISTICS_INFO, (res) => {
                //     if (_this.changeLampPost) {
                //         _this.flvPlayer.pause();
                //         _this.flvPlayer.unload();
                //         _this.flvPlayer.detachMediaElement();
                //         _this.flvPlayer.destroy();
                //         _this.flvPlayer = null;
                //     }
                // })
            }
        },

        destroyVideo(){
            this.flvPlayer.destroy();
            this.flvPlayer= null;
        },
    },
    beforeDestroy() {
        if (this.flvPlayer) {
            this.flvPlayer.destroy();
            this.flvPlayer = null;
        }
    },
    destroyed(){
        let _this = this
        setTimeout(() => {
            _this.flvPlayer.pause()
            _this.flvPlayer.destroy()
            _this.changeLampPost = true; // 这里视频做了优化处理 
        },500)
    },
    watch:{
        changeLampPost: {
            handler(newVal){
                if (newVal) {
                    this.$nextTick(() => {
                        this.destroyVideo();
                    })
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="less" scoped>
.videoGroup{
    width:100%;
    height:100%;
    position:relative;
    overflow: hidden;
}

.flv_box{
    width: 100%;
    height: 100vh;
}
.flvVideo{  //视频撑满
    background-color: #000;
    width:100%;
    height:100%;
    object-fit: fill;
}
</style>
